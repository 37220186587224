
  
  .hero-container {
    height: auto;
    background-color: rgb(223, 223, 223);
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    overflow: hidden;

  }
  .hero-button {
    align-items: center;
    background-image: linear-gradient(144deg,#e66465, #9198e5 50%,#6c7091);
    border: 0;
    border-radius: 8px;
    box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
    box-sizing: border-box;
    color: #FFFFFF;
    display: flex;
    font-family: Phantomsans, sans-serif;
    font-size: 18px;
    justify-content: center;
    line-height: 1em;
    max-width: 100%;
    min-width: 140px;
    padding: 3px;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    cursor: pointer;
    transition: all .3s;
   }
   
   .hero-button:active,
   .hero-button:hover {
    outline: 0;
   }
   
   .hero-button span {
    background-color: rgb(5, 6, 45);
    padding: 16px 24px;
    border-radius: 6px;
    width: 100%;
    height: 100%;
    transition: 300ms;
   }
   
   .hero-button:hover span {
    background: none;
   }
   
   .hero-button:active {
    transform: scale(0.9);
   }

  .section-item1{
    animation: circle 5s ;
    clip-path: circle(75%);
    background: linear-gradient(#e66465, #9198e5);
    border-radius: 23% 7% 13% 77% / 98% 7% 15% 2%     ;
    box-shadow: 3px 2px #ee6262bb, -0.5em 0 .6em #585d9d;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 50%;
    margin: 2%;

  }
  .section-item2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:50%;
    margin: 20px;
  }


    .section-item2 > h1 {
      color: black;
      font-size: 70px;
      margin-bottom: 0%;
    }
    .section-item2-text {
      color:#e66465 ;
      background-image: linear-gradient(45deg, #e66465, #9198e5);
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      font-size: 70px;
      position: relative;
      display: inline-block;
    }
    .section-item2-text::after{
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 2px solid rgb(255, 255, 255);
      z-index: -1;
    }
    
   
    img{
    
      width: 75%;
      height: 10%;
      -webkit-filter: drop-shadow(5px 5px 5px #222);
      filter: drop-shadow(5px 5px 5px #222)
    }
    @keyframes circle{
      0% { clip-path: circle(0%); }
    100% { clip-path: circle(75%); }
    }
  


.subheading{
  font-size: 22px;
  padding: 10px;
}
.bottom-description{
  width: 100%;
  font-size: 18px;
  background-color:  rgb(223, 223, 223);
  display: flex;
  flex-direction: column;
  font-family: Phantomsans, sans-serif;
  justify-content: center;
  align-items: center;
  

}
.bottom-description-border{
  text-align: center;
  background-color: rgb(5, 6, 45);
  color: rgba(255, 255, 255, 0.654);
  padding: 1rem 10rem 1rem 10rem;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px 0px, rgba(0, 0, 0, 0.22) 0px 15px 12px 0px;
  border-bottom: 2px solid white;
}
 
@keyframes floating {
    0% { transform: translate(0,  0px); }
    50%  { transform: translate(0, 15px); }
    100%   { transform: translate(0, -0px); }   
}

@media screen and (max-width: 768px) {
  .hero-container {
    height: auto;
    background-color: rgb(223, 223, 223);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;

  }
  .section-item1{
    animation: circle 5s ;
    clip-path: circle(75%);
    background: linear-gradient(#e66465, #9198e5);
    border-radius: 23% 77% 23% 77% / 98% 85% 15% 2%     ;
    box-shadow: 3px 2px #ee6262bb, -0.5em 0 .6em #585d9d;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 80%;
    margin: 2%;

  }
  .section-item2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:80%;
  }
    .section-item2 > h1 {
      color: black;
      font-size: 55px;
      margin-bottom: 0%;
    }
    .section-item2-text{
      font-size: 55px;
      margin-bottom: 0%;
    };

  .bottom-description{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    
  }
  .bottom-description-border{
    text-align: center;
    background-color: rgb(5, 6, 45);
    color: rgba(255, 255, 255, 0.654);
    padding: 1rem 1rem 1rem 1rem;
    
  }
}