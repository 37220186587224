.past-project-parent{

    padding: 2% 4% 2% 4%;
    margin: none;
    background-color:#f2f2f2;
}

.past-projects{
  
    margin: none;
    justify-content: right;
}


.box-with-tabs {
    display: flex;
  }
  
  .sidebar {
    font: 24px bold;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    width: 15rem;
    padding: 20px;
    border-bottom-right-radius: 10px;
  }
  
  .tab {
    cursor: pointer;
    padding: 5px;
    margin-bottom: 5px;
    transition: all 0.3s ease; /* Define the transition properties */


  }
  .tab:hover{
    background: linear-gradient(45deg, #e664647f, #9198e582);
    border-radius: 5px;
    color: white;
    transition: all 0.3s ease; /* Define the transition properties */

  }
  
  .tab.active {
    background: linear-gradient(45deg, #e66465, #9198e5);
    color: white;
    border-radius: 5px;
    animation-duration: 2s;
  }
  
  .content-container {
   flex: 1;
    }
    
    .content {
    padding: 10px;
    border-radius: 4px;
    margin: none;
    }
    .content h3{
    font-size: 24px;

    }
      .content-text{
        font-size: 18px;
        line-height: 1.5;
        margin: 0 0 1em 0;
        color: #333;
      }
      .content-buttons{
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-top: 1em;
      }
    .project-button {
        display: flex;
        align-items: center;
        font-family: inherit;
        font-weight: 500;
        font-size: 15px;
        padding: 0.8em 1.3em 0.8em 0.9em;
        color: white;
        background: #ad5389;
        background: linear-gradient(to right, #0f0c29, #302b63, #24243e);
        border: none;
        letter-spacing: 0.05em;
        border-radius: 16px;
      }
      
      .project-button svg {
        margin-right: 3px;
        transform: rotate(30deg);
        transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
      }
      
      .project-button span {
        
        transition: transform 0.5s cubic-bezier(0.76, 0, 0.24, 1);
      }
      
      .project-button:hover svg {
        transform: translateX(5px) rotate(90deg);
      }
      
      .project-button:hover span {
        transform: translateX(7px);
      }
      .project-button:hover{
        background: #ad5389;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
        transition: transform 0.5s ease; /* Define the transition properties */
      }
    
    .github-button{
        display: flex;
        align-items: center;
        font-family: inherit;
        font-weight: 500;
        font-size: 17px;
        padding: 0.8em 1.3em 0.8em 0.9em;
        color: white;
        background: linear-gradient(to right, #0f0c29, #302b63, #24243e);
        border: none;
        letter-spacing: 0.05em;
        border-radius: 16px;
        margin: 0  1em 0 0;
    }
      .github-button:hover{
        background: #ad5389;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);
        transition: transform 0.5s ease; /* Define the transition properties */
    }

@media screen and (max-width: 768px) {
    .box-with-tabs {
      flex-direction: column;

    }
  
    .sidebar {
        justify-content:space-evenly;
        display: flex;
        flex-direction: row;
        width: 100%;

    }

    .tab {
        margin: 0 1.5em 0 0;
        padding: 0.5em 0.5em 0.5em 0.5em;
        border-radius: 5px;
    }
  
    .content-container {
      width: 100%;
    }
  }
  
  @media screen and (max-width: 500px) {
    .sidebar {
      font-size: 18px;
    }
  }
  
  @media screen and (max-width: 400px) {
    .sidebar {
      font-size: 16px;
    }
  }
  
  @media screen and (max-width: 300px) {
    .sidebar {
      font-size: 14px;
    }
  }
  
  @media screen and (max-width: 200px) {
    .sidebar {
      font-size: 12px;
    }
  }
  
  @media screen and (max-width: 100px) {
    .sidebar {
      font-size: 10px;
    }
  }

  