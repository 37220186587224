

.navbar {
    background-color: rgb(223, 223, 223);
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 999;
  }
  
  .navbar-container {
    display: flex;
    height: 80px;
    max-width: 1500px;    
  }
  
  .navbar-name {
    color: black;
    font-size: 2rem;
    display: flex;
    align-items: center;
    left: 0;
}
.navbar-name h1{
  font-weight: bolder;
  font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}

.social-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
  border-radius: 5em;
}

.social-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 10px;
  box-shadow: 0px 0px 4px #00000027;
  transition: 0.3s;
}

.social-button:hover {
  background-color: #f2f2f2;
  box-shadow: 0px 0px 6px 3px #00000027;
}

.social-buttons svg {
  transition: 0.3s;
  height: 20px;
}

.facebook {
  background-color: #3b5998;
}

.facebook svg {
  fill: #f2f2f2;
}

.facebook:hover svg {
  fill: #3b5998;
}

.github {
  background-color: #333;
}

.github svg {
  width: 25px;
  height: 25px;
  fill: #f2f2f2;
}

.github:hover svg {
  fill: #333;
}

.linkedin {
  background-color: #0077b5;
}

.linkedin svg {
  fill: #f2f2f2;
}

.linkedin:hover svg {
  fill: #0077b5;
}

.instagram {
  background-color: #c13584;
}

.instagram svg {
  fill: #f2f2f2;
}

.instagram:hover svg {
  fill: #c13584;
}
@media screen and (max-width: 960px) {
  .navbar {
    transition: 0.8s all ease;
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    height: 80px;
    z-index: 1;
    width: 100%;
    padding: 0 24px;
  }
  
  .navbar-name {
    color: black;
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    left: 0;
  }
  
  .navbar-name h1{
    font-weight: bolder;
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
  }
  
  .social-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    border-radius: 5em;
  }
  
  .social-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0 10px;
    box-shadow: 0px 0px 4px #00000027;
    transition: 0.3s;
  }
  
  .social-button:hover {
    background-color: #f2f2f2;
    box-shadow: 0px 0px 6px 3px #00000027;
  }
  
  .social-buttons svg {
    transition: 0.3s;
    height: 20px;
  }
  
  .facebook {
    background-color: #3b5998;
  }
  
  .facebook svg {
    fill: #f2f2f2;
  }
  
  .facebook:hover svg {
    fill: #3b5998;
  }
  
  .github {
    background-color: #333;
  }
  
  .github svg {
    width: 25px;
    height: 25px;
    fill: #f2f2f2;
  }
  
  .github:hover svg {
    fill: #333;
  }
  
  .linkedin {
    background-color: #0077b5;
  }
}
