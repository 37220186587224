.App {
  text-align: center;
}
a{ 
  color: #fff !important;
  text-decoration: none !important;
  cursor: pointer;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

h2 {
  color: black;
font-family: Phantomsans, sans-serif;
font-size: 36px;
font-weight: bold;

}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


