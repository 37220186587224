
.form-container {
    max-width: 500px;
    margin: 3rem auto;
    background-color: rgb(5, 6, 45);
    color: white;
    padding: 2rem 3rem 2rem 2rem;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    border-bottom: 4px solid rgb(131, 131, 131);
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px 0px, rgba(0, 0, 0, 0.22) 0px 15px 12px 0px;
}

  
  .form-container label {
    display: block;
    margin-bottom: 0.5rem;
  }
  .form-container h2{

    color: white;
  }
  .form-container input,
  .form-container textarea {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: none;
  }
  

  
  .form-container button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  .submit-button {
    position: relative;
    left: 36%;
    background-image: linear-gradient(144deg,#e66465, #9198e5 50%,#6c7091);
    border: 0;
    border-radius: 8px;
    box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
    box-sizing: border-box;
    color: #FFFFFF;
    display: flex;
    font-family: Phantomsans, sans-serif;
    font-size: 18px;
    justify-content: center;
    line-height: 1em;
    max-width: 100%;
    min-width: 140px;
    padding: 3px;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    cursor: pointer;
    transition: all .3s;
   }
   
   .submit-button:active,
   .submit-button:hover {
    outline: 0;
   }
   
   .submit-button span {
    background-color: rgb(5, 6, 45);
    padding: 16px 24px;
    border-radius: 6px;
    width: 100%;
    height: 100%;
    transition: 300ms;
   }
   
   .submit-button:hover span {
    background: none;
   }
   
   .submit-button:active {
    transform: scale(0.9);
}

@media screen and (max-width: 768px) {
    .form-container {
        max-width: 500px;
        margin: 3rem auto;
        background-color: rgb(5, 6, 45);
        color: white;
        padding: 2rem 3rem 2rem 2rem;
        border-radius: 10px;
        justify-content: center;
        align-items: center;
        border-bottom: 4px solid rgb(131, 131, 131);
        box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px 0px, rgba(0, 0, 0, 0.22) 0px 15px 12px 0px;
    }
    .submit-button {
        position: relative;
        left: 36%;
        background-image: linear-gradient(144deg,#e66465, #9198e5 50%,#6c7091);
        border: 0;
        border-radius: 8px;
        box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
        box-sizing: border-box;
        color: #FFFFFF;
        display: flex;
        font-family: Phantomsans, sans-serif;
        font-size: 18px;
        justify-content: center;
        line-height: 1em;
        max-width: 100%;
        min-width: 140px;
        padding: 3px;
        text-decoration: none;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
        white-space: nowrap;
        cursor: pointer;
        transition: all .3s;
       }
       
       .submit-button:active,
       .submit-button:hover {
        outline: 0;
       }
       
       .submit-button span {
        background-color: rgb(5, 6, 45);
        padding: 16px 24px;
        border-radius: 6px;
        width: 100%;
        height: 100%;
        transition: 300ms;
       }
       
       .submit-button:hover span {
        background: none;
       }
       
       .submit-button:active {
        transform: scale(0.9);
    }
}
  


