.About-container{
    background-color: #f2f2f2;
    padding: 1% 4%;
    margin: none;
    display: flex;
    flex-direction: column;
}
.About-container h1{
    font-size: 2rem;
    font-weight: 600;
    display: flex;
    justify-content: right;
}
.About-content{
    border: 1px solid #cbcbcb;
    border-radius: 10px;
    padding: 2rem;
    display: flex;
    flex-direction: row;

}
.About-content-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 80%;
}

.About-content p{
    font-size: 1.2rem;
    font-weight: 400;
    margin-bottom: 0;
    
}
.About-content-image{
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.About-content-image img{
    width: 100%;
    height: auto;
    border-radius: 10px;
    -webkit-filter: drop-shadow(5px 5px 5px #222);
    filter: drop-shadow(5px 5px 5px #222);
}

@media screen and (max-width: 768px){
    .About-container{
        padding: 2% 2%;
    }
    .About-content{
        flex-direction: column;
    }
    .About-content-text{
        margin-left: 0;
        width: 100%;
    }
    .About-content-image{
        margin-top: 2rem;
        width: 100%;
    }
    .About-content-image img{
        display: none;
    }
}
