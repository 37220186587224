.footer-container {
  background-color: #242424;
  padding: 4rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.footer-subscription {
  text-align: center;
  margin-bottom: 24px;
  color: #fff;

}

.footer-subscription-heading {
  margin-bottom: 24px;
  font-size: 24px;
}

.footer-subscription-text {
  margin-bottom: 24px;
  font-size: 20px;
}

.footer-subscription-text a {
  color: #fff !important;
  text-decoration: none !important;
}

.footer-subscription-text a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}
.icon-section {
  display: flex;
  gap: 10px;
}

.icon-link {
  color: #fff;
  font-size: 24px;
  text-decoration: none;
}

.icon-link:hover {
  color: rgb(213, 213, 213);
}

@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }
}
